<template>
  <div>

    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        Informação pessoal
      </h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>

        <!-- Field: Birth Date -->
        <b-col
          cols="12"
          md="4"
          lg="4"
        >
          <b-form-group
            label="Data de aniversário"
            label-for="birthday"
          >
            <flat-pickr
              v-model="userData.birthday"
              class="form-control"
              :config="{locale: Portuguese}"
              placeholder="00/00/0000"
            />
          </b-form-group>
        </b-col>

        <!-- Field: phone -->
        <b-col
          cols="12"
          md="4"
          lg="4"
        >
          <b-form-group
            label="Telefone"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="userData.phone"
              v-mask="['(##) ####-####', '(##) #####-####']"
              placeholder="(00) 00000-0000"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Gender -->
        <b-col
          cols="12"
          md="4"
          lg="4"
        >
          <b-form-group
            label="Gênero"
            label-for="gender"
            label-class="mb-1"
          >
            <b-form-radio-group
              id="gender"
              v-model="userData.gender"
              :options="gender"
              value="male"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Header: Personal Info -->
      <div class="d-flex mt-2">
        <feather-icon
          icon="MapPinIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Endereço
        </h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">

        <!-- Field: Address -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Logradouro"
            label-for="address"
          >
            <b-form-input
              id="address"
              v-model="userData.address"
              placeholder="Informe logadouro"
            />
          </b-form-group>
        </b-col>

        <!-- Field: number address -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Número"
            label-for="number_address"
          >
            <b-form-input
              id="number_address"
              v-model="userData.number_address"
              placeholder="0000"
            />
          </b-form-group>
        </b-col>

        <!-- Field: district -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Bairro"
            label-for="district"
          >
            <b-form-input
              id="district"
              v-model="userData.district"
              placeholder="Informe o bairro"
            />
          </b-form-group>
        </b-col>

        <!-- Field: City -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Cidade"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="userData.city"
              placeholder="Informe a cidade"
            />
          </b-form-group>
        </b-col>

        <!-- Field: State -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Estado"
            label-for="state"
          >
            <v-select
              v-model="userData.state"
              :options="states"
              :reduce="val => val.value"
              :clearable="false"
              input-id="state"
              placeholder="Selecione"
            />
          </b-form-group>
        </b-col>

        <!-- Field: zipcode -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="CEP"
            label-for="zipcode"
          >
            <b-form-input
              id="zipcode"
              v-model="userData.zipcode"
              v-mask="'#####-###'"
              type="text"
              placeholder="00000-000"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updateUser()"
          >
            Atualizar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'

import constants from '@/common/constants'
import vSelect from 'vue-select'
import { mask } from 'vue-the-mask'
import store from '@/store'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, flatPickr, BFormInput, vSelect, BFormRadioGroup, BButton,
  },
  directives: {
    mask,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const toast = useToast()
    const { states } = constants
    const gender = [
      { value: 1, text: 'Masculino' },
      { value: 2, text: 'Feminino' },
    ]

    const updateUser = () => {
      store.dispatch('user/updateUser', props.userData)
        .then(e => {
          const resp = e.data
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Atualização de usuário',
              icon: resp.success ? 'CheckIcon' : 'XIcon',
              variant: resp.success ? 'success' : 'danger',
              text: resp.message,
            },
          })
        })
    }

    return {
      gender,
      states,
      updateUser,
      Portuguese,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
