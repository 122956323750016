<template>
  <b-form>
    <b-row>
      <b-col cols="12">
        <b-form-group
          size="lg"
          :label="$t('Usuários')"
          label-for="users"
        >
          <v-select
            ref="selectUsers"
            v-model="immediatesSelected"
            width="100%"
            input-id="users"
            :options="option_users"
            :filterable="false"
            label="name"
            @open="onOpen"
            @search="onSearch"
            @option:selected="optionSelected"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                {{ $t('No results found for') }} <em>{{ search }}</em>.
              </template>
              <em
                v-else
                style="opacity: 0.5"
              >{{ $t('Sorry, No matching options.') }}</em>
            </template>
            <template #list-footer>
              <li
                v-show="hasNextPage"
                ref="load"
                class="loader"
              >
                {{ $t('Loading more options...') }}
              </li>
            </template>
          </v-select>
        </b-form-group>
        <b-row>
          <b-col
            v-for="immediate in immediates"
            :key="immediate.id"
            cols="12"
            md="6"
            lg="4"
          >
            <b-media
              vertical-align="top"
              class="mb-3"
            >
              <template #aside>
                <b-avatar
                  square
                  :src="immediate.avatar"
                  blank-color="#ccc"
                  width="64"
                  alt="placeholder"
                />
              </template>
              <h4 class="media-heading">
                {{ immediate.name }} ({{ immediate.r_code }})
              </h4>
              <b-card-text class="mb-0">
                <small> {{ immediate.office }}</small>
              </b-card-text>
              <b-card-text class="mb-0">
                <b-button
                  variant="outline-danger"
                  size="sm"
                  @click="removeImmediate(immediate.id)"
                >
                  {{ $t('Remover') }}
                </b-button>
              </b-card-text>
            </b-media>
          </b-col>
        </b-row>
      </b-col>

      <b-col class="mt-2">
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="save"
        >
          Salvar imediato
        </b-button>
        <b-button
          variant="outline-secondary"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="reset"
        >
          {{ $t('Resetar') }}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BMedia, BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axios from '@axios'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, vSelect, BMedia, BImg,
  },
  data() {
    return {
      immediatesSelected: [],
      immediates: [],
      observer: null,
      limit: 10,
      option_users: [],
      next_page: false,
    }
  },
  computed: {
    hasNextPage() {
      return this.next_page
    },
  },
  created() {
    this.getImmediates()
  },
  methods: {
    getImmediates() {
      axios.get(`user/${this.$router.currentRoute.params.id}`)
        .then(res => {
          const { immediates } = res.data.data
          this.immediates.push(...immediates)
        })
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.search(search, loading)
      }
    },
    search(search, loading) {
      return axios.get('user', { params: { q: search } })
        .then(res => {
          const { data, meta } = res.data
          this.option_users.splice(0)
          data.forEach(element => {
            if (
              !this.option_users.find(option => option.id === element.id)
                  && element.id != this.$router.currentRoute.params.id
            ) { this.option_users.push(element) }
          })
          this.next_page = meta.current_page < meta.last_page
          loading(false)
          if (this.hasNextPage) {
            this.observer.observe(this.$refs.load)
            this.next_page = meta.current_page < meta.last_page
          }
        })
    },
    async onOpen() {
      axios.get('user', { name: this.search, r_code: this.search })
        .then(res => {
          const { data, meta } = res.data
          data.forEach(element => {
            if (
              !this.option_users.find(option => option.id === element.id)
                && element.id != this.$router.currentRoute.params.id
            ) { this.option_users.push(element) }
          })
        })
    },
    optionSelected(selectedOption) {
      this.immediates.push(selectedOption)
      this.immediatesSelected = []
    },
    removeImmediate(imediateId) {
      this.immediates = this.immediates.filter(immediate => imediateId !== immediate.id)
    },
    reset() {
      this.immediates.splice(0)
      this.getImmediates()
    },
    save() {
      const immediates = this.immediates.map(i => ({ immediate_id: i.id }))
      axios.post('/user/update-immediates', { id: this.$router.currentRoute.params.id, immediates }, { showToast: true })
    },
  },
}
</script>

<style scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}
</style>
