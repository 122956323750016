<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="user.avatar"
          :text="avatarText(user.name)"
          :variant="`light-primary`"
          size="80px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ user.name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="outline-primary"
          @click="$refs.refInputEl.click()"
          size="sm"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <feather-icon
            icon="RepeatIcon"
            size="18"
          />
        </b-button>
        <b-button
          variant="outline-danger"
          class="ml-1"
          @click="removeImage"
          size="sm"
        >
          <feather-icon
            icon="Trash2Icon"
            size="18"
          />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: registration -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Matrícula"
            label-for="registration"
          >
            <b-form-input
              id="registration"
              v-model="user.r_code"
              placeholder="0000"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Nome"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="user.name"
              placeholder="Nome completo"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="user.email"
              type="email"
              placeholder="joao@cosama.am.gov.com.br"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Office -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Cargo"
            label-for="office"
          >
            <b-form-input
              id="office"
              v-model="user.office"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Setor"
            label-for="user-sector"
          >
            <v-select
              v-model.number="user.sector_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sector"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-sector"
              placeholder="Selecione"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Head office -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Sede"
            label-for="head_office"
          >
            <v-select
              v-model="user.head_office"
              :options="head_office"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="head_office"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Status"
            label-for="user-status"
          >
            <v-select
              v-model="user.is_active"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="status"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-status"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Password -->
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            label="Senha"
            label-for="password"
          >
            <b-form-input
              id="password"
              v-model="user.password"
              placeholder="******"
              type="password"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group :label="$t('Perfis')">
            <v-select
              v-model="user.roles"
              multiple
              :options="rolesData"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <!-- PERMISSION TABLE -->
    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permissões</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
        :fields="[
          { key: 'module', label: $t('Modulo') },
          { key: 'permissions', label: $t('Permissoes') },
        ]"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell(permissions)="permissions">
          <b-form-checkbox-group
            v-model="permissionsSelected"
            value-field="name"
            text-field="permission"
            :options="permissions.value"
          />
        </template>
      </b-table>
    </b-card>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="BtnUpdateUser()"
    >
      Atualizar
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckboxGroup,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { reactive, ref } from '@vue/composition-api'
import constants from '@/common/constants'
import store from '@/store'
import axios from '@axios'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckboxGroup,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { sector } = constants
    const { head_office } = constants
    const { status } = constants
    const user = reactive(props.userData)
    const userAbility = props.userData.ability.map(ability => {
      if (ability.type === 'direct') return ability.name
    }).filter(val => val != null)

    const permissionsData = ref([])
    const permissionsSelected = ref(userAbility)
    axios
      .get('/permission/gruoped-by-module')
      .then(res => permissionsData.value.push(...res.data))

    const rolesData = ref([])
    const rolesSelected = ref([])
    axios
      .get('/roles')
      .then(res => rolesData.value.push(...res.data.data.map(role => role.name)))
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        // eslint-disable-next-line no-param-reassign
        user.avatar = base64
      },
    )
    const removeImage = () => {
      user.avatar = null
    }

    const BtnUpdateUser = () => {
      const userData = {
        ...user,
        direct_permissions: permissionsSelected.value,
      }
      store.dispatch('user/updateUser', userData).then(() => {
        axios({
          method: 'post',
          url: '/general/users/update/especific',
          headers: {
            'Content-Type': 'application/json',
          },
          data: { list: [user.r_code] },
        }).then(res => {
          console.log(res)
          emit('updated', true)
        })
      })
    }

    const setInteger = num => {
      console.log(num)
      return 1
    }

    return {
      user,
      BtnUpdateUser,
      avatarText,
      inputImageRenderer,
      permissionsData,
      refInputEl,
      previewEl,
      sector,
      head_office,
      status,
      permissionsSelected,
      rolesData,
      rolesSelected,
      removeImage,
      setInteger,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.b-avatar .b-avatar-text {
  font-size: 22px !important;
}

</style>
